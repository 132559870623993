<template>
    <div class="flex-1 p-4 bg-primary flex flex-wrap justify-center w-full background">
        <div class="text-white text-center w-2/3">
            <p class="text-xl">Let's Build Something Extraordinary Together!</p>
            <p class="mt-5">
                Embark on a journey of innovation with HSDE Consulting. Partner with us to turn your visions into reality,
                solve
                complex challenges, and transform the way you do business.
                <i>Together, we'll create a future where your technology
                    empowers success.</i>
            </p>
            <p class="mt-5">Contact us today to discuss how HSDE Consulting can elevate your software development journey.
            </p>
            <br>
            <p class="text-3xl"><a href="mailto:info@hsde.se">Contact us</a></p>
        </div>
</div></template>
<script setup></script>