<template>
  <div class="flex-1 p-4 bg-primary text-gray-50 text-center">
    Why choose us?
  </div>

  <div class="flex flex-wrap justify-center mt-10 mb-10">
    <div class="box max-w-sm bg-slate-300">
      <div class="shadow-button shadow-header-drop w-80 text-center">Tailored Solutions</div>
      <div>
        <p class="text-3xl">We understand that each business is unique.</p>
        <br>
        Our experienced team works closely with you to
        analyze your specific needs and develops tailor-made software solutions that align perfectly with your goals.
      </div>
    </div>

    <div class="box max-w-sm bg-slate-300">
      <div class="shadow-button shadow-header-drop w-80 text-center">Problem Solvers</div>
      <div>
        <p class="text-3xl">Your challenges are our challenges.</p><br> We thrive on unraveling complexities and turning
        obstacles into
        opportunities. Our problem-solving approach ensures that every project is an opportunity for growth and
        improvement.
      </div>
    </div>

    <div class="box max-w-sm bg-slate-300">
      <div class="shadow-button shadow-header-drop w-80 text-center">Cutting-Edge Technology</div>
      <div>
        <p class="text-3xl">We stay ahead of the curve.</p><br> Our team is proficient
        in the latest technologies, ensuring that your software solutions are not only robust but also future-proof.
      </div>
    </div>

    <div class="box max-w-sm bg-slate-300">
      <div class="shadow-button shadow-header-drop w-80 text-center">Client-Centric Focus</div>
      <div>
        <p class="text-3xl">Your satisfaction is our top priority.</p><br> We believe in transparent communication,
        continuous
        collaboration, and delivering results that exceed expectations. Your success is our success.
      </div>
    </div>
  </div>
</template>
<script setup></script>
