<!-- WhatWeDo.vue -->
<template>
    <div class="flex-1 p-4 bg-primary text-gray-50 text-center">
        What we often use
    </div>
    <div class="flex flex-wrap -mx-4 justify-center pt-10 pb-10">
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-plain-wordmark.svg" alt="Java"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg" alt="C#"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg" alt="spring"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg" alt="React"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg" alt="AngularJS"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original-wordmark.svg" alt="VueJS"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-original-wordmark.svg"
                alt="Tailwind" class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" alt="JS"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
                alt="TypeScript" class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" alt="Python"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain.svg"
                alt="sqlserver" class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/oracle/oracle-original.svg" alt="Oracle"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apache/apache-original-wordmark.svg" alt="Apache"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg" alt="Docker"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" alt="Git"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/gradle/gradle-plain-wordmark.svg" alt="Gradle"
                class="w-full h-auto">
        </div>
        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/grafana/grafana-original.svg" alt="Grafana"
                class="w-full h-auto">
        </div>

        <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
            <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg"
            alt="AWS" class="w-full h-auto">
    </div>
    <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" alt="VsCode"
            class="w-full h-auto">
    </div>
    <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/intellij/intellij-original-wordmark.svg"
            alt="IntelliJ" class="w-full h-auto">
    </div>
    <div class="w-1/6 sm:w-1/6 md:w-1/6 lg:w-1/12 xl:w-1/24 p-4">
        <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg" alt="Jenkins"
            class="w-full h-auto">
    </div>
</div></template>

<script setup>

</script>