<template>
  <div>
    <div class="bg-primary">
      <div class="flex items-center">
        <!-- Left Block -->
        <div
          class="border-2 border-green-700 w-12 h-12 rounded-full flex items-center justify-center text-white font-semibold ml-2">
          &lt;...&gt;
        </div>
        <!-- Right Block -->
        <div class="ml-2 border-l-gray-500 border-2 border-t-0 border-r-0 border-b-0 text-white">
          <div class="p-2">
            <p class="text-lg font-semibold ">HSDE</p>
            <p class="text-sm">Consulting AB</p>
          </div>
        </div>
      </div>
    </div>

    <CompanyIntro></CompanyIntro>
    <WhyUs></WhyUs>
    <OurServices></OurServices>
    <WhatWeDo></WhatWeDo>
    <WhoWeWorkWith></WhoWeWorkWith>
    <LetsGo></LetsGo>

  </div>
  <div class="bg-primary text-slate-400">
    Image by <a
      href="https://www.freepik.com/free-photo/collage-customer-experience-concept_25053678.htm#query=customer%20background&position=1&from_view=keyword&track=ais&uuid=15d01156-f92b-4ca1-a424-d782a4275f98">Freepik</a>
  </div>
</template>

<script>
import WhyUs from "@/components/WhyUs.vue"
import OurServices from "@/components/OurServices.vue";
import CompanyIntro from "@/components/CompanyIntro.vue";
import WhatWeDo from './components/WhatWeDo.vue';
import WhoWeWorkWith from './components/WhoWeWorkWith.vue';
import LetsGo from "@/components/LetsGo.vue"

export default {
  name: 'App',
  components: { WhatWeDo, WhoWeWorkWith, CompanyIntro, OurServices, WhyUs, LetsGo }
};
</script>


<style>
/* Include Tailwind CSS classes */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

p {
  z-index: 1;
  /* Ensure the text is above the background overlay */
  position: relative;
  /* Ensure the text is positioned relative to the .back container */
}

.shadow-button {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.shadow-header-drop {
  background-color: #2d3a4b;
  /* Adjust the color as needed */
  box-shadow: 0 4px 8px rgba(45, 58, 75, 0.5);
}

.shadow-header-drop:hover {
  background-color: #1f2b38;
  /* Adjust the hover color as needed */
  box-shadow: 0 8px 16px rgba(45, 58, 75, 0.7);
}
.box {
  border: 2px solid #2d3a4b;
  /* Adjust the border color as needed */
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
}
</style>


