<template>
  <div class="flex-1 p-4 bg-primary text-gray-50 text-center">
      Our Services
    </div>
    <div class="w-full background">
    <div class="flex flex-wrap justify-center pt-10 pb-10">
      <div class="box max-w-sm bg-gray-300">
        <div class="shadow-button shadow-header-drop w-80 text-center">Custom Software Development</div>
        <div>
          <p class="text-3xl">Innovate, Build, Excel</p><br> From concept to deployment, we build software that aligns
          with your unique
          requirements, providing a competitive edge in your industry.
        </div>
      </div>
      <div class="box max-w-sm bg-gray-300">
        <div class="shadow-button shadow-header-drop w-80 text-center">Consulting Services</div>
        <div>
          <p class="text-3xl">Empowering Your Journey</p><br>
          Whether you need advice on technology strategy or guidance through a digital transformation,
          our consultants are here to support you.
        </div>
      </div>
      <div class="box max-w-sm bg-gray-300">
        <div class="shadow-button shadow-header-drop w-80 text-center">Problem Identification and Resolution</div>
        <div>
          <p class="text-3xl">Your Shield Against Future Challenges</p><br> We don't just solve problems; we identify the
          root causes and implement
          strategic solutions to prevent future challenges.
        </div>
      </div>
      <div class="box max-w-sm bg-gray-300">
        <div class="shadow-button shadow-header-drop w-80 text-center">Innovation at Every Step</div>
        <div>
          <p class="text-3xl">Innovate Today, Lead Tomorrow </p><br> Our commitment to innovation ensures that your
          software solutions are not only functional
          but also at the forefront of industry trends.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
/* Additional styles specific to your component */
.background {
  margin: 0;
  padding: 0;
  position: relative;
  background-image: url('../assets/services.jpg');
  background-size: cover;
  background-position: center;
  min-height: 40vh;
  /* Set a minimum height to cover the entire viewport */
  display: flex;
  flex-direction: column;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 242, 242, 0.5);
  /* Adjust the transparency as needed */
}
</style>