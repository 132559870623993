<template>
  <div class="background">
    <div class="bg-transparent text-black text-left p-2 text-2xl mx-auto md:w-1/2 mt-5 mb-5 ">
      <p class="text-center">Your Innovation Partner in Custom Software Development</p>
      <br>
      <p>At HSDE Consulting, we are not just developers</p>
      <p class="mt-2">We are your trusted partners in crafting innovative
        solutions to
        propel your business forward.</p>

      <p class="mt-2">With a commitment to excellence and a passion for problem-solving, we specialize in custom software
        development,
        helping our clients overcome challenges and embrace opportunities in the ever-evolving digital landscape.</p>
    </div>
  </div>
</template>

<script setup>

</script>
<style scoped>
/* Additional styles specific to your component */
.background {
  margin: 0;
  padding: 0;
  position: relative;
  background-image: url('../assets/intro.jpg');
  background-size: cover;
  background-position: center;
  min-height: 40vh;
  /* Set a minimum height to cover the entire viewport */
  display: flex;
  flex-direction: column;
}

.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 242, 242, 0.5);
  /* Adjust the transparency as needed */
}
</style>