<!-- WhoWeWorkWith.vue -->
<template>
  <div class="flex-1 p-4 bg-primary text-gray-50 text-center">
    Who we do it with
  </div>
  <div class="w-full background">
    <div class="flex-1 p-4 text-black text-center text-2xl mx-auto md:w-1/2 mt-5 mb-5 backgroundtextblock">
      Our company specializes in custom software development, partnering with a diverse range of clients to address their
      specific challenges and
      capitalize on opportunities. Our collaborative approach has allowed us to work closely with notable organizations in
      sectors such as telecommunications,
      government, retail, and technology. By crafting tailored solutions,
      we empower our clients to navigate their unique landscapes, fostering innovation and growth. Our experience spans
      various industries,
      reflecting our adaptability and commitment to delivering impactful software solutions. Through strategic
      collaboration, we help our clients
      stay ahead in their respective fields, creating value and achieving their business objectives.
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
/* Additional styles specific to your component */
.background {
  margin: 0;
  padding: 0;
  position: relative;
  background-image: url('../assets/customers.jpg');
  background-size: cover;
  background-position: center;
  min-height: 40vh;
  /* Set a minimum height to cover the entire viewport */
  display: flex;
  flex-direction: column;
}

.background:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252, 0.75);
  /* Adjust the transparency as needed */
}

.backgroundtextblock {
  position: relative;
}

.backgroundtextblock::before {
  background-color: rgba(252, 252, 252, 0.5);
}</style>